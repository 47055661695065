.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.ScrollingRows {
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  flex-grow: 1;
}

.ContentWrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.ContentRowScrollingWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  /* padding-left: 60px; */
}

.ContentRowScrollingContent {
  display: flex;
  flex-direction: row;
}

::-webkit-scrollbar {
  display: none;
}

.menuWrapper {

  height: 500px;
  width: 500px;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;


}

.video-js .vjs-control-bar {
  height: 6em;
}

.railContainer {
  position: relative;
}

.arrows {
  width: 20px;
  height: 20px;
  border-color: #000;
  position: absolute;
  z-index: 10000;
  top: 70px;
}

.prev {
  position: absolute;
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(45deg);
  left: 10px;
}


.next {
  position: absolute;

  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(-135deg);
  right: 10px;

}

.herobanner {
  width: 100%;
  height: 100%;
  border-radius: 10px;

}

.heroBannerContainer {
  background-color: #61dafb;
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  border-radius: 10px;
}