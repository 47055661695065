#login {
    background: black;
    /* height: 100vh;
    width: 100vw; */
}

.loginLogo {
    height: 20vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

}

.loginLogo img {
    height: 80%;
    width: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

}

#loginContent {
    display: flex;
    height: 80vh;
    width: 100vw;
}

.keypadOuterDiv {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.keypadOuterDiv:after {
    content: "";
    background-color: white;
    position: absolute;
    width: 2px;
    height: 40%;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
}



.loginInputOuterDiv {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#keypad {
    margin: 0;
    padding: 0;
    list-style: none;
}

#keypad li {
    float: left;
    margin: 0 15px 15px 0;
    width: 90px;
    height: 90px;
    font-size: 24px;
    line-height: 90px;
    text-align: center;
    background: #363435;
    color: white;
    border-radius: 3px;
    font-weight: bold;
}

#keypad li.active {
    background: white;
    color: black;
    position: relative;
    top: 1px;
    left: 1px;
    cursor: pointer;
}


.clearl {
    clear: left;
}

#keypad .space {
    float: left;
    width: 556px;
}

.loginInputOuterDiv .loginInput {
    width: 400px;
    height: 70px;
    border: 1px solid white;
    border-radius: 50px;
    background: white;
    user-select: none;
    font-size: 22px;
    padding: 0 20px;
    font-weight: normal;
    margin-bottom: 50px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
}

.loginInputOuterDiv .loginButton {
    width: 150px;
    height: 60px;
    border: 1px solid white;
    border-radius: 30px;
    background: black;
    color: white;
    user-select: none;
    font-size: larger;
    padding: 0 20px;
    font-weight: 700;
    cursor: pointer;
    letter-spacing: 1px;
}

.loginInputOuterDiv .loginButton.active {
    background: white;
    color: black;
}

.otpOuterDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 50vw;
}

.otpOuterDiv li {
    list-style: none;
    width: 70px;
    height: 70px;
    background-color: white;
    margin: 0 20px;
    margin-bottom: 50px;
    border-radius: 6px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 70px;
}

#searchContent {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.keypadFullOuterDiv {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 50px;
}


#keypadFull {
    margin: 0;
    padding: 0;
    list-style: none;
}

#keypadFull li {
    float: left;
    margin: 0 5px 5px 0;
    width: 70px;
    height: 70px;
    font-size: 24px;
    line-height: 70px;
    text-align: center;
    background: #363435;
    color: gray;
    border-radius: 3px;
    font-weight: bold;
    cursor: pointer;
}

#keypadFull li.expand {
    width: 220px !important;
    font-size: 56px;
}

#keypadFull li.expand i {
    font-size: 36px;
}


#keypadFull li.active {
    color: white;
    position: relative;
    border: 1px solid white;
}

#keypadFull .space {
    float: left;
    width: 556px;
}

.searchRightSideOuterDiv {
    width: 60%;
    height: 100%;
}