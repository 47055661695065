

.Player {
  position: absolute;
}

.player-total-time {
  margin-right: 3rem;
  margin-top: 0;
}


.vjs-back-icon{

    color: #fff;
    display: block;
    font-size: 2rem;
    height: 3%;
    left: 2%;
    position: fixed;
    top: 2%;
    width: 3%;
    z-index: 100;

}


.player-title-visibility-hide {
  opacity: 0;
  transition: visibility 1.1s, opacity 1.1s;
  visibility:visible;
}

.video-js .vjs-control-bar {
  background-color: transparent;
  bottom: 1.65rem;
}

.video-js .vjs-load-progress,
.video-js .vjs-remaining-time,
.video-js .vjs-icon-placeholder,
.video-js .vjs-volume-control {
  /*  background-color: #dd38c9; */
  display: none;
}

.video-js {
  color: black;
}



.video-js .vjs-progress-control {
  
  position: absolute;
  width: 95%;
 
  margin-top: 1.5rem;
}

.video-js .vjs-play-progress {
  background-color: #dd38c9;
  margin-top: 100rem;
}

.vjs-slider-bar {
  font-size: 1.55rem;
}

.video-js .vjs-progress-holder {
  height: 0.8em;
}

.video-js .vjs-live-control{
    font-size: 2em;
    line-height: 2.2em;
    color: red;
    font-weight: 700;
    position: fixed;
    margin-left: 7rem;
    margin-bottom: 100rem;
    right: 0%;
    top: 4%;

}

.video-js .vjs-duration {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  left: 72.5rem;
  position: absolute;
  visibility: collapse;
}

.player-title {
    color: aliceblue;
    font-size: 1.9rem;
    margin-left: 2.3rem;
    overflow: hidden;
    position: fixed;
    text-overflow: ellipsis;
    bottom: 5%;
    visibility: visible;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    width: 94%;
    z-index: 9;
  }

.video-js .vjs-current-time {
 position: fixed;
  align-items: center;
  color: #fff;
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  height: auto;
 
  right: 0%;
  margin-bottom: 4rem;
  width: fit-content;

}



.video-js .vjs-play-control .vjs-icon-placeholder {
    color: #fff;
    display: block;
    font-size: 2rem;
    height: 3%;
    left: 48%;
    position: fixed;
    top: 36%;
    width: 3%;
  }

.Loader .Image img {
  height: 10rem;
  width: 10rem;
}

.hidePlayPauseIcon {
  visibility: hidden;
}

.video-js .vjs-big-play-button {
  background-color: transparent;
  border: none;
}

.Player_error {
  background-color: #dd38c9;
  height: 100vh;
  position: relative;
  width: 100%;
}

.page_player {
  left: 0;
  position: fixed;
  top: 0;
  width: 80rem;

  .Loader {
    background: transparent;
  }
}

