body {
  background-color: #000;
}

.textColor {
  color: antiquewhite;
}

.carousel-indicators li {
  margin: 0 10px !important;
  width: 10px !important;
  height: 10px !important;
  background-color: #000 !important;
  border-radius: 50%;
}

.card-img {
  border-radius: 10px;
  cursor: pointer;
  transition: transform .4s;
  transform: scale(0.9);
  object-fit: cover;
}

.card-img:hover {
  border-radius: 10px;
  /* transform: scale(1); */
}

.card-img-circle {
  border-radius: 200px;
  cursor: pointer;
  transition: transform .4s;
  transform: scale(0.9);

}

.card-img-card-img-circle:hover {
  border-radius: 200px;
  transform: scale(1);
}

#carouselExampleIndicators1 {
  padding-bottom: 10px;
}

.round-img {
  border-radius: 30px;
  aspect-ratio: 32/9;
  margin-right: 100px;
}

.img-focused {
  transform: scale(1);
  margin: 10;
}

.swiper-pagination-bullet {
  background-color: aliceblue;
}

.round-img {
  border-radius: 15px;
  max-height: 100%;
  max-width: 100%;


}

.rail-img {
  max-height: 100%;
  max-width: 100%;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 5px;
  object-fit: cover;
  transition: all 0.4s;
}

.rail-img-circle {
  border-radius: 50% !important;
  height: 100px;
  width: 100px;
  user-select: none;
  -webkit-user-drag: none;
  transition: all 0.4s;
  object-fit: cover;
}


.rail-img-circle-tv {
  border-radius: 50% !important;
  height: 150px;
  width: 150px;
  user-select: none;
  -webkit-user-drag: none;
  transition: all 0.4s;
  object-fit: cover;
}

.grid-img {
  transition: all 0.4s;
}

.grid-img:hover {
  transform: scale(1.02);
}

.rail-img:hover {
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.3);
}

.rail-box-circle {
  height: 100px;
  width: 100px;
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}


.rail-box-circle-tv {
  height: 150px;
  width: 150px;
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}

.rail-box-circle:hover {
  transform: scale(1.0);
}

.rail-box {
  cursor: pointer;
  transform: scale(0.9);
  transition: all 0.4s;
}

.rail-box:hover {
  transform: scale(0.95);
}

.carousel-dots {
  padding-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  .round-img {
    aspect-ratio: 16/9;
  }

}

.round-img-mobile {
  border-radius: 10px;
  max-height: 100%;
  max-width: 100%;
}



.mobilePosition {
  position: absolute;
  top: -50px;
  left: 35%;
  scale: 0.80;
}

._1HaoU {
  width: 200px !important;
  height: 40px !important;
  color: black !important;

}



._3ZP5s li {
  color: black;
}

.herobannerMobile {
  background-color: bisque;
}

.rail-title {
  margin-top: 5px;
  margin-bottom: 1px;
  margin-left: 7px !important;

}

.style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #575151;
}

.style-4::-webkit-scrollbar {
  width: 5px;
  background-color: #575151;
}

.style-4::-webkit-scrollbar-thumb {
  background-color: #5a5151;
  border: 2px solid #575151;
}

.pi-page {
  width: 100vw;
  height: 100%;
  position: relative;
  z-index: -1;
}

.pi-page .imageOverlayBlur {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  filter: blur(150px);
  z-index: -1;
  opacity: 0.7;
}

.pi-page .imageOverlay {
  height: 25rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 30vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  transition: background-image 0.23s linear
}


.pi-page-main {
  width: fit-content;
  padding-top: 50px;
  padding-left: 50px;
}

.pi-page-main .type {
  font-weight: normal;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  letter-spacing: 1px;
}

.pi-page-main .title {
  font-weight: bold;
  font-size: 26px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  margin-bottom: 3px;
}

.pi-page-main .genre {
  color: green;
  font-weight: bolder;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  letter-spacing: 1px;
  margin-right: 12px;
}

.pi-page-main .year {
  font-weight: bolder;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  padding-right: 12px;
  letter-spacing: 2px;
}

.pi-page-main .rating {
  color: rgb(177, 173, 173);
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 3px 12px;
  background-color: #575151;
  letter-spacing: 1px;
  margin-right: 12px;
}

.pi-page-main .duration {
  font-weight: 700;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  margin-right: 12px;
  letter-spacing: 1px;
}

.play-button {
  font-weight: normal;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  width: 400px;
  user-select: none;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.15s ease-in-out;
}

.pi-page-main .description {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.arrows {
  z-index: 1;
  cursor: pointer;
}

.crown {
  position: absolute;
  top: 5px;
  right: 25px;
  height: 25px;
  width: 25px;
  z-index: 10000000;
  transition: all .4s;
}

.crown.focus {
  top: -10px;
  right: 5px;
  height: 30px;
  width: 30px;
}

.circleCrown {
  position: absolute;
  top: 5px;
  right: 35%;
  height: 25px;
  width: 25px;
  z-index: 10000000;
  transition: all .4s;
}

.circleCrown.focus {
  top: -10px;
  right: 30%;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 800px) {

  .rail-box-circle {
    height: 95px;
    width: 95px;
  }

  .card-img-circle {
    height: 95px;
    width: 95px;
  }

  #contentDiv {
    margin-top: 0vh !important;
    height: 100% !important;
    padding-bottom: 100px !important;
    padding-top: 75px;
  }

  .pi-page-main {
    padding-left: 20px;
  }

  .play-button {
    left: 20px !important;
    width: 90% !important;
  }

}